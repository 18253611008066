import { React, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

export default function ClientHome() {
  const navigate=useNavigate();
  const enterFullscreen = () => {  //temp
    const elem = document.documentElement;

    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.webkitRequestFullscreen) { // Safari
      elem.webkitRequestFullscreen();
    } else if (elem.msRequestFullscreen) { // IE/Edge
      elem.msRequestFullscreen();
    }
  navigate('/customerhome');
  }
return (
    <>
    <nav className="custom-navbar" data-spy="affix" data-offset-top="20">
     {/*   <div className="container">
            <a className="logo" href="#" style={{margin:'auto',marginTop:'7%'}}>2CQR GARMENTS</a>
        </div>
  */}
    </nav>
   <header id="home" className="header">
        <div className="overlay"></div>
        <div className="header-content container">
            <button className="btn btn-primary" style={{color:'#fff'}} onClick={enterFullscreen}>Let's Start</button>
        </div>
    </header>
</>
  )
}
