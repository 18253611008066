import {  Route, Routes,HashRouter } from 'react-router-dom';
import './App.css';
import Home from './pages/Home';
import Login from './pages/Login';
import Dashboard from './pages/Dashboard';
import ClientHome from './pages/ClientHome';

function App() {
  return (
    <div>
      <HashRouter>
      <Routes>
        <Route path="/" element={<ClientHome/>} />
        <Route path="/customerhome" element={<Home/>} />
        <Route path="/login" element={<Login/>} />
        <Route path="/dashboard" element={<Dashboard/>} />
</Routes>
      </HashRouter>
    </div>
  );
}

export default App;
