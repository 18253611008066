import { React, useEffect } from 'react';
import { Link } from 'react-router-dom';

export default function Home() {
 
return (
    <>
    <nav className="custom-navbar" data-spy="affix" data-offset-top="20">
     {/*   <div className="container">
            <a className="logo" href="#" style={{margin:'auto',marginTop:'7%'}}>2CQR GARMENTS</a>
        </div>
  */}
    </nav>
   <header id="home" className="header">
        <div className="overlay"></div>
        <div className="header-content container">
            <Link className="btn btn-primary" style={{color:'#fff'}} to="/login">Click Here to Start Purchase</Link>
        </div>
    </header>
</>
  )
}
