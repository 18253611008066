import { useEffect, React, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { API_URL } from '../ApiUrl';
import { useNavigate } from 'react-router-dom';


export default function Dashboard() {

  const [products, setProducts] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const[confirmmsg, setConfirmmsg]=useState("");
  const[cusstatus,setCusstatus]=useState("");
  const storedPhoneNumber = localStorage.getItem("phonenumber");
  const storedisLoggedin = localStorage.getItem("isLoggedin");
  const [totprodprice,setTotalProdPrice]=useState(0);
  const navigate = useNavigate();

  useEffect(() => {


    if (storedisLoggedin !== "true") {
      navigate("/login");
    }


    const fetchProductsForNumber = async () => {
      try {
        const response = await axios.post(`${API_URL}/api/fetch-customerproducts`, { phonenumber: storedPhoneNumber });
        if (response.data.data) {
          setProducts(response.data.data);
          const total = response.data.data.reduce((acc, product) => acc + parseFloat(product.price), 0);
          setTotalProdPrice(total);
      }

      } catch (error) {
        console.error('Error fetching products:', error);
      }
    };

    setInterval(fetchProductsForNumber, 5000);

  }, [storedisLoggedin, navigate, products]);

  const logout = async () => {
    setShowModal(false);
    const response = await axios.post(`${API_URL}/logout`, { phonenumber: storedPhoneNumber,status:cusstatus });
    localStorage.setItem('isLoggedin', false);
    navigate("/login");
  }
const HandleCancel=()=>{

  setShowModal(true);
  setCusstatus("Cancelled");
  setConfirmmsg("Are you sure you want to cancel this transaction?");
}
const HandlePay=()=>{

  setShowModal(true);
  setCusstatus("Completed");
  setConfirmmsg("Are you sure you want to End this transaction?");
}
  return (
    <>

      <header id="dashboard" className="header_dashboard">
        <div className="overlay"></div>
        <section className="container section" id="product_list">
          <div className="container">
            <div className="row">
              {products && products.length > 0 ? products.map(product => (
                <div key={product.ean} className="col-md-12 mb-12 col-sm-12">
                  <div className="card product_card">
                    <div className="card-body">
                      <div className='row'>
                        <div className='col-sm-4'>
                          <h6 className="card-title">{product.product_name}</h6>
                          <p className="card-text">
                            <strong>Brand:</strong> {product.brand} | <strong>EAN:</strong> {product.ean}<br />
                            <strong>Price (MRP):</strong> ₹{product.price} | <strong>Size:</strong> {product.size}<br />
                        </p>
                        </div>
                        <div className='col-sm-5'>
                        <h6 className="card-title text-right">₹{product.price} </h6>
                        </div>
                        <div className='col-sm-1'>
                          </div>
                        <div className='col-sm-2'>
                          <span><img src={`${API_URL}${product.prodimg}`} style={{width:'40%'}} /></span>
                          
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )) : <h2 className="centered-content">Please Place your Product...</h2>
              }
            </div>
          </div>
        </section>
        <section className="container section" id="product_summary">
          <div className="container">
            <div className="row">

              <div className="col-md-12 mb-12 col-sm-12">
                <div className='col-sm-8'>
                  <div className='order_details'>
                    <p className="card-title">Order Details</p>
                    <table>
                      <tbody>
                      <tr>
                        <td>
                    <p className="card-text">
                      <strong>Total Items:</strong>&nbsp;{products.length}<br />
                      </p>
                      </td>
                      </tr>
                      <tr>
                       <td>
                       <p className="card-text">
                      <strong>Total Cost:</strong> ₹{totprodprice} <br />
                      </p> 
                      </td>
                     
                     </tr>
                     </tbody>
                     </table>
                  </div>
                </div>
              </div>


            </div>
          </div>
        </section>
        <section className="container section" id="payemnt_button_sec">
          <div className="container">
            <div className="row">
              <div className="d-flex justify-content-center col-sm-12">
                <button className="btn btn-primary mr-5 rounded-pill col-sm-2" style={{ color: '#fff' }} onClick={() => HandleCancel()}>CANCEL</button>
                <button className="btn btn-success rounded-pill col-sm-2 ml-5" style={{ color: '#fff' }} onClick={() =>HandlePay()}>PAY</button>
              </div>
              {showModal && (
        <div className="modal show" style={{ display: 'block' }}>
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Confirmation</h5>
                <button type="button" className="close" onClick={() => setShowModal(false)}>
                  <span>&times;</span>
                </button>
              </div>
              <div className="modal-body">
                {confirmmsg}
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" onClick={() => setShowModal(false)}>Close</button>
                <button type="button" className="btn btn-primary" onClick={logout}>Confirm</button>
              </div>
            </div>
          </div>
        </div>
      )}
            </div>
          </div>
        </section>


      </header>
    </>
  )
}
