import React from 'react';
import axios from 'axios';
import { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Modal } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import {API_URL} from '../ApiUrl';
export default function Login() {

  const navigate=useNavigate();
  const [phoneNumber, setPhoneNumber] = useState('');
  const inputRef = useRef(null);
  const handleButtonClick = (number) => {
    const newPhoneNumber = phoneNumber + number;
    if (isValidValue(newPhoneNumber)) {
      setPhoneNumber(newPhoneNumber);
    }
    console.log('Number set using dialer button');
  };

  const clearNumber = () => {
    setPhoneNumber('');
  };
  const handleDeleteAtCaret = () => {

    if (!inputRef.current) {
      return;
    }

    const input = inputRef.current;
    const startPosition = input.selectionStart;
    const endPosition = input.selectionEnd;

    if (startPosition !== endPosition) {
      // Delete a selected range
      setPhoneNumber(
        phoneNumber.slice(0, startPosition) + phoneNumber.slice(endPosition)
      );
    } else if (startPosition > 0) {
      // Delete the character just before the cursor position
      setPhoneNumber(
        phoneNumber.slice(0, startPosition - 1) + phoneNumber.slice(startPosition)
      );
    }
    input.focus();  // To keep the input focused after the operation
  };
  const isValidValue = (value) => {
    return /^\d{0,10}$/.test(value);
  };
  const handleInputChange = (event) => {
    const value = event.target.value;


    if (/^\d{0,10}$/.test(value)) {

      setPhoneNumber(value);
    }
  };


  //modal popup
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [zipcode, setZipcode] = useState("");
  const [show, setShow] = useState(false);
  const axiosJWT = axios.create();
  const handleShow = () => {
    setShow(true);
  }
  const handleClose = () => {
    setShow(false);
    setName("");
    setEmail("");
    setPhoneNumber("");
    setAddress("");
    setState("");
    setCity("");
    setZipcode("");
  }
  const handleKeyDown = (event) => {
    const keyCode = event.which || event.keyCode;
    const keyValue = String.fromCharCode(keyCode);
    if (!/^\d+$/.test(keyValue) && event.keyCode !== 8 && event.keyCode !== 9) {
      event.preventDefault();
    }
    if (event.target.value.length >= 10 && event.keyCode !== 8 && event.keyCode !== 9) {
      event.preventDefault();
    }
  };
  const handleZipcode = (event) => {
    const keyCode = event.which || event.keyCode;
    const keyValue = String.fromCharCode(keyCode);
    if (!/^\d+$/.test(keyValue) && event.keyCode !== 8 && event.keyCode !== 9) {
      event.preventDefault();
    }
    if (event.target.value.length >= 6 && event.keyCode !== 8 && event.keyCode !== 9) {
      event.preventDefault();
    }
  };


  const Proceed=async(data)=>{
    if (phoneNumber.trim() === '') {
      toast.error("Please Enter Phone Number...!", { autoClose: 1000 });
      return;
    }
    if (phoneNumber.toString().length !== 10) {
      toast.error("Please Enter 10 Digit Phone Number...!", { autoClose: 1000 });
      return;
    }
    try {
       const response=await axios.post(`${API_URL}/login`, {
          phone: phoneNumber,
      });
      localStorage.setItem("username", response.data.name);
      localStorage.setItem("phonenumber", response.data.phonenumber);
      localStorage.setItem("lastlogin", response.data.lastlogin);
      localStorage.setItem('isLoggedin', true);
      toast.success("PhoneNumber Matched", { autoClose: 1000 });
      setTimeout(() => {
        navigate("/dashboard");
      }, 1000);
    }catch (error) {
      if(error.message==='Network Error'){

       //toast.error(error.message, { autoClose: 1000 });
       toast.error("Please Contact Admin", { autoClose: 1000 });
      }
       else if (error.response) {
           toast.error(error.response.data.msg);
       }
   }

  };
  const saveCustomer = async (data) => {
    data.preventDefault();
    if (name.trim() === '' || email.trim() === '' || phone.toString().trim() === '' || address.trim() === '' || state.trim() === '' || city.trim() === '' || zipcode.toString().trim() === '') {
      toast.error("Please Enter All Fields...!", { autoClose: 1000 });
      return;
    }
   

    if (phone.toString().length !== 10) {
      toast.error("Please Enter 10 Digit Phone Number...!", { autoClose: 1000 });
      return;
    }
    if (zipcode.toString().length !== 6) {
      toast.error("Please Enter 6 Digit Zipcode...!", { autoClose: 1000 });
      return;
    }

    const isValidEmail = (email) => {
      // Regular expression for email validation
      const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return regex.test(email);
    }
    if (!isValidEmail(email)) {
      toast.error("Please Enter valid Email...!", { autoClose: 1000 });
      return;
    }
    try {
        const response = await axiosJWT.post(`${API_URL}/createcustomer`, {
          name,
          email,
          phone,
          address,
          state,
          city,
          zipcode,
        },
         
        );
       // toast.success(response.data.msg, { autoClose: 1000 });
        toast.success("Please Enter Registered Number to Proceed", { autoClose: 2000 });
      setName("");
      setEmail("");
      setPhoneNumber("");
      setAddress("");
      setState("");
      setCity("");
      setZipcode("");
      //toast.success('Data saved successfully!', { autoClose: 3000 });
     
      setTimeout(() => {
        handleClose();
      }, 1000);
    } catch (error) {
      //console.log(error.response.data.error);
      if (error.response && error.response.data && error.response.data.error) {
        toast.error(error.response.data.error);
      } else {
        toast.error('Please wait...!');
      }
    }
  };

  //modal end
  return (
    <>

      <header id="home" className="header">
        <div className="overlay"></div>
        <div className="header-content container" style={{ marginLeft: 'inherit' }}>

          <div className="row col-sm-8 pb-4" style={{ background: '#32a9b61f' }}>

            <div className="col-sm-8">
              <div className='mobile_div'>
                <div className='rfid_home_img_div'>
                  <img src="/homescreen/assets/imgs/rfid_image_trans.png" style={{ width: '40%' }} alt="rfid_img"/>
                </div>
                <p className='phn_num_head'><b>Please Enter Your<br></br>Mobile Number</b></p>
                <div style={{ display: 'flex', alignItems: 'center' }}> {/* Flex container */}
                  <input
                    type="text" ref={inputRef}
                    className='form-control number' readOnly
                    value={phoneNumber}
                    onChange={handleInputChange} />
                  <button onClick={clearNumber} className='btn btn-dark clear_button'>Clear</button>
                </div>
              </div>
            </div>

            <div className="col-sm-4">
              <div className='mobile_div_dailer'>
                {[1, 2, 3, 4, 5, 6, 7, 8, 9].map((number) => (
                  <button
                    key={number}
                    onClick={() => handleButtonClick(number)}
                    className="btn btn-light dailerbtn" // Bootstrap's classes for button styling
                    style={{ borderRadius: '5px' }}
                  >
                    {number}
                  </button>
                ))}
                <button
                onClick={() => handleButtonClick(0)}
                  className="btn btn-light"
                  style={{ borderRadius: '5px' }}
                >
                  0
                </button>
                <button
                  onClick={handleDeleteAtCaret}
                  className="btn btn-light"
                  style={{ borderRadius: '5px' }}
                >
                  <i className='ti-control-backward'></i>
                </button>
              </div>


            </div>
            <div className="d-flex justify-content-center col-sm-8">
    <Link className="btn btn-primary mr-1" style={{ color: '#fff' }} to="/customerhome">Back</Link>
    <button className="btn btn-danger mr-1" onClick={handleShow}>New Customer</button>
    <button className="btn btn-success" style={{ color: '#fff' }} onClick={Proceed}>Proceed</button>
</div>




            <Modal show={show} onHide={handleClose} className='modal-lg'>
              <Modal.Header closeButton>
                <Modal.Title>New Customer</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="row">
                  <div className="col-sm-6">
                    <div className="card-body">
                      <div className="row row-cards">
                        <div className="col-sm-12 col-md-12">
                          <div className="mb-3">
                            <label className="form-label">Name *</label>
                            <input type="text" className="form-control" placeholder="Client Name" value={name} onChange={(e) => setName(e.target.value)} />
                          </div>
                        </div>



                        <div className="col-sm-12 col-md-12">
                          <div className="mb-3">
                            <label className="form-label">Email Address *</label>
                            <input type="email" className="form-control" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} />
                          </div>
                        </div>
                        <div className="col-sm-12 col-md-12">
                          <div className="mb-3">
                            <label className="form-label">Address *</label>
                            <textarea className="form-control" rows="5" placeholder="Address" value={address} onChange={(e) => setAddress(e.target.value)} ></textarea>
                          </div>
                        </div>



                      </div>
                    </div>
                  </div>

                  <div className="col-sm-6">
                    <div className="card-body">
                      <div className="row row-cards">
                        <div className="col-sm-12 col-md-12">
                          <div className="mb-3">
                            <label className="form-label">Phone No *</label>
                            <input type="text" className="form-control" placeholder="Phone No" value={phone} onChange={(e) => setPhone(e.target.value)} onKeyDown={handleKeyDown} />
                          </div>
                        </div>
                        <div className="col-sm-12 col-md-12">
                          <div className="mb-3">
                            <label className="form-label">City *</label>
                            <input type="text" className="form-control" placeholder="City" value={city} onChange={(e) => setCity(e.target.value)} />
                          </div>
                        </div>

                        <div className="col-sm-12 col-md-12">
                          <div className="mb-3">
                            <label className="form-label">State *</label>
                            <input type="text" className="form-control" placeholder="State" value={state} onChange={(e) => setState(e.target.value)} />
                          </div>
                        </div>
                        <div className="col-sm-12 col-md-12">
                          <div className="mb-3">
                            <label className="form-label">Postal Code *</label>
                            <input type="text" className="form-control" placeholder="ZIP Code" value={zipcode} onChange={(e) => setZipcode(e.target.value)} onKeyDown={handleZipcode} />
                          </div>
                        </div>


                      </div>
                    </div>
                  </div>
                </div>

              </Modal.Body>
              <Modal.Footer>
                <div className="">
                  <button className='btn btn-md btn-primary ms-auto' onClick={saveCustomer}>Save</button>
                </div>
              </Modal.Footer>
            </Modal>
            <ToastContainer />
          </div>



        </div>
      </header>

    </>
  )
}
